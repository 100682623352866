


.bigNavbar {
  background-color: black;
  display: flex;
  color: white;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0; 
  
}

.shiftMegamenueLeft {
  left:0px !important;
}

.movePintexNavDown {
  /* z-index: 1000; Ensures the header is above other content */
  top: 45px; 

}

.banner {
    z-index: 2000; 
    /* Ensures the header is above other content */

}

.mobileBanner {
position: static  /* Ensures the header is above other content */

}

.mobileCalloutEvidence {
 margin-left: px;
 background-color: #8e00f112;
 border-radius: 7px;
 margin-top: -10px;
 color: #402060;
 /* height: 48px; */
 padding-top: 10px;
 
 /* padding-tophow to make top of div : 10px; */
}

.fullCalloutEvidence {
  display: flex;
  /* Center horizontally */
  justify-content: center; 
  align-items: center; /* Center vertically */
  
  /* padding-tophow to make top of div : 10px; */
 }

 .fullCalloutColoring {
  position: absolute;
  background-color: #8e00f112;
 border-radius: 7px;
 font-size: 15px;
 /* margin-top: -10px; */
 color: #402060;
 /* height: 48px; */
 padding-top: 10px;
 }


.smallNav{
  background-color: black;
  display: flex;
  color: white;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0; 
  z-index: 1000; /* Ensures the header is above other content */
  
}
.homeLink{
    margin:0 auto;
    font-size: 20px;
    background-color: white;
    
    border-color: black;
    border-radius: 7px;

}

.navLinks {
  text-decoration: none;
  color: white;
}

.hideLink {
  text-decoration: none;
  color: black;

}

.smallNavMenu {
  z-index: 1000;
  background-color: white;
  position: fixed;
  border-radius: 5px;

  width: 90%;
  /* width: 20px;
  height: 20px; */
  transition: background-color 0.3s ease; /* Smooth transition */

}

.smallNavMenu a:hover {
  border-radius: 10px;

  background-color: rgb(204, 204, 204); /* Background color on hover */
}


/* Styles for small devices */
@media (max-width: 575.98px) {
  .main {
     padding-top: 50px;
  }
  .bigNavbar {
    display: none;
  }


}

/* Styles for medium devces */
@media (min-width: 576px) and (max-width: 767.98px) {
  .main {
    padding-top: 50px;
 }

 .smallNav, .smallNavMenu {
  display: none;
}
}

/* Styles for large devices */
@media (min-width: 768px) {
  .main {
    padding-top: 50px;
 }

 .smallNav, .smallNavMenu {
  display: none;
}
}