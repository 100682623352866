.Home {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
      
  }

  button{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI (Custom)', Roboto, 'Helvetica Neue', 'Open Sans (Custom)', system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'
    ;
  }
  
  .homeNavButton{
    height: 100%;
    /* margin:0 auto; */
    /* font-size: 100px; */
    /* align-self: center; */
 
}
.learn{
    /* margin:0 auto; */
   /* width: 100px; */
   align-self: center;
    
}
.separateFromMain {
  margin-top: 30px;
}

/* <!-- HTML !-->
<button class="button-31" role="button">Button 31</button> */

CSS
.homeNavButton {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: white;
    background-color: #171717;
    padding: 1em 2em;
    border: none;
    border-radius: .6rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  
  .homeNavButton span:not(:nth-child(6)) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    width: 30px;
    background-color: #0c66ed;
    border-radius: 50%;
    transition: .6s ease;
  }
  
  .homeNavButton span:nth-child(6) {
    position: relative;
  }
  
  .homeNavButton span:nth-child(1) {
    transform: translate(-3.3em, -4em);
  }
  
  .homeNavButton span:nth-child(2) {
    transform: translate(-6em, 1.3em);
  }
  
  .homeNavButton span:nth-child(3) {
    transform: translate(-.2em, 1.8em);
  }
  
  .homeNavButton span:nth-child(4) {
    transform: translate(3.5em, 1.4em);
  }
  
  .homeNavButton span:nth-child(5) {
    transform: translate(3.5em, -3.8em);
  }
  
  .homeNavButton:hover span:not(:nth-child(6)) {
    transform: translate(-50%, -50%) scale(4);
    transition: 1.5s ease;
  }
    